<template>
    <div class="d-flex justify-space-between">
        <v-dialog
            v-model="dialog"
            width="1200"
            v-if="showReviewDialog"
        >
            
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    fab
                    dark
                    color="primary"
                >
                    <v-icon>
                        {{ mdiAccountMultipleOutline }}
                    </v-icon>
                </v-btn>
            </template>  

            <v-card>
                <v-card-title class="text-h6 grey lighten-2 d-flex justify-space-between mr-2 ml-2">
                    Team Score {{teamScore}}
                    <v-chip
                        v-if='teamPenalty'
                        color="error"
                    >
                        Team Penalty {{teamPenalty}}
                    </v-chip>
                </v-card-title>

                <v-card-text class="mt-3" v-if="teamData.data">
                    <v-data-table
                        :items-per-page="itemsPerPage"
                        :headers="headers"
                        :items="teamData.data"
                        hide-default-footer
                        :search="search"/>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { fetchProjectScoresAdmin } from '../../api/course'
    import { mdiAccountMultipleOutline } from '@mdi/js'; 

    export default {
        props: {
            teamName: { type: String, default: null },
            courseName: { type: String, default: null },
            reviewPhase: { type: Number, default: null },
            teamScore: { type: String, default: null },
            teamPenalty: { type: Number, default: 0 },
            showReviewDialog: { type: Boolean, default: false }
        },
        mounted(){ 
        },
        data () {
            return {
                dialog: false,
                teamData: [],
                itemsPerPage: 20,
                search: '',
                mdiAccountMultipleOutline,
                headers: [
                    {
                        text: 'Student Name',
                        align: 'start',
                        value: 'name'
                    },
                    { text: 'Roll No.', value: 'rollNumber' },
                    { text: 'Student Score', value: 'student_score'},
                    { text: 'SPA', value: 'student_spa' },
                    { text: 'Penalized (No Peer Review)?', value: 'penalty' },
                    { text: 'Team Penalty %', value: 'team_penalty' }
                ],
            }
        },
        methods: {
            async fetchProjectScoresAdmin(teamName, courseName, reviewPhase){
                try{
                    const apiData = await fetchProjectScoresAdmin({
                        teamName, 
                        courseName,
                        reviewPhase
                    })
                    
                    this.teamData = JSON.parse(JSON.stringify(apiData))
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                        message: e.response.data.details || e.response.data.error.message,
                        type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                        message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                        type: 'error',
                        });
                    }
                }
            },
        },
        watch:{
            dialog(n, o) {
                if(n) {
                    this.fetchProjectScoresAdmin(this.teamName, this.courseName, this.reviewPhase)
                }
            },
            numStudents(n, o){
                this.$nextTick(() => { // Make sure add student happens first.
                    if(this.showReviewDialog){
                        this.fetchProjectScoresAdmin(this.teamName, this.courseName, this.reviewPhase)
                    }
                });
            }
        }
    }
</script>

