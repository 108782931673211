<template>
    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="error"
                v-bind="attrs"
                v-on="on"
            >
                {{isDelete ? 'Delete Project': 'Reject Project Details'}}
            </v-btn>
        </template>

        <v-card class='pa-2'>
            <v-card-title>
                Delete Permanently?
            </v-card-title>
            
            <v-alert
                dense
                colored-border
                color="error"
                border="right"
            >
                Please note this action cannot be undone. 
                You will be deleting {{isDelete ? 'the approved Project Phases too.': 'the associated un-approved project phases as well. If there is a previously approved version of the project, we will revert to it.'}}
                Proceed with caution!
            </v-alert>
        
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="accent"
                small
                outlined
                @click="closeDialog"
            >
                Do not delete
            </v-btn>
            <v-btn
                color="error"
                small
                outlined
                @click="deleteParams"
            >
                Confirm
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { deleteProject } from '../../api/course'

    export default {
        props: {
            teamName: { type: String, default: null },
            courseName: { type: String, default: null },
            isDelete: { type: Boolean, default: false}
        },
        computed:{
        },
        mounted(){
        },
        data () {
            return {
                deleteDialog: false,
            }
        },
        methods: {
            async deleteParams(){
                try{
                    await deleteProject({teamName: this.teamName, courseName: this.courseName, isApproved: this.isDelete})
                    this.deleteDialog = false;
                    this.$emit('delete')
                }
                catch(e){
                    this.closeDialog()
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.response.data.details || e.response.data.error.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                        message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                        type: 'error',
                        });
                    }
                }
            },
            closeDialog(){
                this.deleteDialog = false;
                this.$emit('refresh')
            }
        },
    }
</script>