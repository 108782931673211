<template>
    <div>
        <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="infoDialog = true" class="mt-3" large color="accent" v-bind="attrs" v-on="on">
                {{mdiInformation}}
              </v-icon>
            </template>
            <span>Click for Calculation Info (& Research Paper)</span>
        </v-tooltip>

        <v-dialog
            v-model="infoDialog"
            max-width="900"
        >
            <v-card>
            <v-card-title class="text-h5">
                Calculation Method Info
            </v-card-title>

            <v-card-text>
                This tool collects self and peer ratings and calculate an adjustment factor to convert group marks into an individual mark for a project. This has the following benefits:
                <ul class='mt-2'>
                <li>It enables students to confidentially rate their own and their peers' contributions to a team project against criteria which can be written to include specific project tasks and/or good team practices.</li>
                <li>The Self and Peer Assessment or SPA factor is a weighting factor that can be used to determine an individual's contribution to a team project. <strong> A SPA factor of 1 indicates a student’s contribution was rated as being equal to the average contribution of their team </strong></li>
                </ul> 
            </v-card-text>

            <v-card-text>
                <div class='subtitle-1 font-weight-bold'> SPA Formula</div>
                <v-img
                :src="require('@/assets/images/formulas/spa.png')"
                max-height="90px"
                max-width="750px"
                alt="SPA Formula"
                contain
                eager
                class="mt-2"
                >
                </v-img> 
            </v-card-text>

            <v-card-text>
                <div class='subtitle-1 font-weight-bold'> Line vs Knee Method</div>
                <p class="mt-2">
                Our tool also allows the use of different formulas and rating scales to accommodate the design of assessment tasks with different objectives. 
                </p>
                <ul class='font-weight-medium'>
                <li>Linear SPA = (Equation 1)</li>
                <li>Knee SPA = (Equation 1) squared if SPA ≤ 1 or Equation 1 if SPA > 1</li>
                </ul>  
            </v-card-text>


            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                @click="infoDialog = false"
                >
                Close
                </v-btn>

                <v-btn
                color="primary"
                @click="downloadFile"
                >
                Download the full paper
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>  
    </div>  
</template>

<script>

import {  mdiInformation } from '@mdi/js'
import {  downloadSparkPaper } from '../api/course';

export default{
    data() {
        return {
            mdiInformation,
            infoDialog: false,
        }
    },
    methods: {
        async downloadFile() {
            try{
                const response = await downloadSparkPaper();
                const fileURL = window.URL.createObjectURL(response);
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = 'sparkPaper.pdf';
                fileLink.setAttribute('download', fileName);
                fileLink.setAttribute('target', '_blank');
                document.body.appendChild(fileLink);
                fileLink.click();
                fileLink.remove();
            }
            catch(e){
                console.log(e)
                this.$toast.open({
                message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                type: 'error',
                });
            }
        }
    }
}


</script>
