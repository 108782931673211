<template>
    <div class="d-flex justify-space-between">
        <v-dialog
            v-model="dialog"
            width="1024"
            v-if="showReviewDialog"
        >
            
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    fab
                    dark
                    color="primary"
                >
                    <v-icon>
                        {{ mdiCommentOutline }}
                    </v-icon>
                </v-btn>
            </template>  

            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    Comments
                </v-card-title>

                <v-tabs v-model="tab">
                    <v-tab  v-for="(c) in students" :key="c.id"> {{c.name}} ( {{c.rollNumber}})</v-tab>
                </v-tabs>

                <v-card-text class='mt-2' v-if="students.length > 0">
                    <v-sheet elevation="1">
                        <div class="pa-2">
                            <span class="subtitle-1"> Obervations made by Peers</span>
                        </div>
                        <v-data-table
                            :items-per-page="itemsPerPage"
                            :headers="headers"
                            :items="peerComments"
                            hide-default-footer
                            :search="search"/>
                    </v-sheet>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { fetchProjectTeamMemberComments } from '../../api/course'
    import { mdiCommentOutline } from '@mdi/js'; 

    export default {
        props: {
            teamName: { type: String, default: null },
            courseName: { type: String, default: null },
            reviewPhase: { type: Number, default: null },
            showReviewDialog: { type: Boolean, default: false }
        },
        mounted(){ 
        },
        computed: {
            students(){
                if(this.teamData && this.teamData && this.teamData.length > 0){
                    return this.teamData
                }
                else{
                    return []
                }
            },
            comments(){
                if(this.students.length > 0) {
                    return this.students[this.tab].comments
                }

                return []
            },
            peerComments(){
                let comments = [];

                for(let i = 0; i < this.comments.length; i++){
                    if(!this.comments[i].selfComment){
                        comments.push(this.comments[i])
                    } 
                }

                return comments;
            },
            selfComments(){
                let comments = [];

                for(let i = 0; i < this.comments.length; i++){
                    if(this.comments[i].selfComment == 1){
                        comments.push(this.comments[i])
                    } 
                }

                if(comments.length == 0){
                    return {comment:'Not Available'}
                }

                return comments[0];
            }
        },
        data () {
            return {
                dialog: false,
                teamData: [],
                mdiCommentOutline,
                tab: 0,
                itemsPerPage: 20,
                search: '',
                headers: [
                    {
                        text: 'Evaulated By',
                        align: 'start',
                        value: 'awardedByName'
                    },
                    { text: 'Roll No.', value: 'awardedByRollNumber' },
                    { text: 'Comment', value: 'comment' },
                ],
            }
        },
        methods: {
            async fetchComments(teamName, courseName, reviewPhase){
                try{
                    const apiData = await fetchProjectTeamMemberComments({
                        teamName, 
                        courseName,
                        reviewPhase
                    })
                    
                    let studentTeams = JSON.parse(JSON.stringify(apiData))
                    let cleanData = []
                    
                    for(let i = 0; i < studentTeams.data.length; i++){
                        let data = studentTeams.data[i]
                        data.comments= JSON.parse(data.comments)
                        cleanData.push(data)
                    }

                    this.teamData = cleanData
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.response.data.details || e.response.data.error.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                            type: 'error',
                        });
                    }
                }
            },
        },
        watch:{
            dialog(n, o) {
                if(n) {
                    this.fetchComments(this.teamName, this.courseName, this.reviewPhase)
                }
            },
            numStudents(n, o){
                this.$nextTick(() => { // Make sure add student happens first.
                    if(this.showReviewDialog){
                        this.fetchComments(this.teamName, this.courseName, this.reviewPhase)
                    }
                });
            }
        }
    }
</script>