<template>
  <div>
      <v-row>
        <v-col  cols="6" md="6">
          <v-select
              clearable
              :items="courses"
              return-object
              item-value="id"
              item-text="desc"
              label="Select the Course (For the Final Project Teams)"
              solo
              v-model="selectedCourse"
              @change="fetchTeams"
              @click:clear="removeCourse"
          >
          </v-select>
        </v-col>

        <v-col cols="4" md="4">
          <v-switch
            v-if="reviewPhase && selectedCourse && selectedCourse.id"
            v-model="isCalcKnee"
            inset
            :label="`Knee Method`"
            @change="saveParameter()"
          >
          </v-switch>
        </v-col>

        <v-col cols="2" md="2" class="d-flex justify-end">
          <info-dialog />
        </v-col>
      </v-row>

      <v-row class="mb-2">
        <v-col cols="6" md="6" v-if="selectedCourse && selectedCourse.id">
          <div class="d-flex">
            <span class="mr-2 mt-2">
              Activate Phase
            </span>
            <v-btn-toggle
              title
              dense
              rounded
              v-model="reviewPhase"
            >
              <v-btn active-class="secondary"  @click="saveReviewParameters(0)">
                None
              </v-btn>
              <v-btn active-class="success"  @click="saveReviewParameters(1)">
                Review-1
              </v-btn>
              <v-btn active-class="success"  @click="saveReviewParameters(2)">
                Review-2
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>

        <v-col cols="6" md="6" v-if="selectedCourse && selectedCourse.id">
          <div class="d-flex justify-end">
            <v-btn outlined color="primary" class="mr-2" @click="exportData(1)" :loading="report1">
              <v-icon left>
                {{mdiDownloadCircleOutline}}
              </v-icon>
              Export Review-1 Data
            </v-btn>
            <v-btn outlined color="primary" class="mr-2"  @click="exportData(2)" :loading="report2">
              <v-icon left>
                {{mdiDownloadCircleOutline}}
              </v-icon>
              Export Review-2 Data
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-divider/>
      
      <v-row class="mt-2" v-if="selectedCourse && selectedCourse.id">
        <v-col  cols="12" md="9" style="height: 90vh; overflow-y: scroll">
          <v-row>
            <v-col v-for="(team, idx) in teams" :key="team.teamName"
              cols="12"
              md="4"
            >
              <v-card>
                <div class="pt-1" :style="cardColor(idx)"></div>
                <v-card-title class="d-flex justify-space-between" >
                  {{team.teamName}} 
                  <approval-modal 
                    @refresh="closedApprovalModal"
                    :courseName='selectedCourse.id'
                    :teamName='team.teamName'
                    :shouldApprove='team.needsApproval === 0 ? false : true'
                    :showReviewDialog='team.hasSchedule == 0 ? false : true'/>
                </v-card-title>

                <v-card-text v-if='(team.students && team.students.length > 1 && team.hasSchedule == 0) ? true : false'>
                  <v-chip small color='error'>Has no schedule</v-chip>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-text class="d-flex justify-space-between">
                  <v-chip medium>R1</v-chip>
                  <all-reviews-dialog 
                    reviewPhase=1
                    :numStudents="team.students.length"
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :courseName='selectedCourse.id'>
                  </all-reviews-dialog>
                  <student-scores-dialog
                    reviewPhase=1
                    :teamScore="team.teamScore"
                    :teamPenalty="team.penalty"
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :courseName='selectedCourse.id'>
                  </student-scores-dialog>
                  <student-comments-dialog
                    reviewPhase=1
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :courseName='selectedCourse.id'>
                  </student-comments-dialog>

                </v-card-text>
                <v-divider></v-divider>

                <v-card-text class="d-flex justify-space-between">
                  <v-chip medium>R2</v-chip>
                  <all-reviews-dialog 
                    reviewPhase=2
                    :numStudents="team.students.length"
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :courseName='selectedCourse.id'>
                  </all-reviews-dialog>
                  <student-scores-dialog
                    reviewPhase=2
                    :teamScore="team.teamScore"
                    :teamPenalty="team.penalty"
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :courseName='selectedCourse.id'>
                  </student-scores-dialog>
                  <student-comments-dialog
                    reviewPhase=2
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :courseName='selectedCourse.id'>
                  </student-comments-dialog>
                </v-card-text>
                <v-divider></v-divider>
                  <review-dialog
                    :teamName="team.teamName" 
                    :courseName='selectedCourse.id'>
                  </review-dialog>            
                <v-divider></v-divider>
                <v-card-text class="d-flex justify-space-between" v-if='team.hasSchedule == 0 ? false : true'>
                  <span>Team Score </span>
                  <v-chip
                    small
                    color="error"
                    v-if="team.penalty"
                  >
                    Penalized
                  </v-chip>
                  <span>
                    {{team.teamScore}}
                    <v-icon @click="scoreDialog(team.teamName, team.teamScore, team.penalty)">
                      {{mdiPencil}}
                    </v-icon>
                  </span>
                </v-card-text>

                <v-divider v-if='team.hasSchedule == 0 ? false : true'></v-divider>

                <v-card-text v-if='team.hasSchedule === 1 && parseInt(team.numberOfPhases) === 0'>
                  <v-chip color='error' small>Phases Not Defined</v-chip>
                </v-card-text>
                
                <v-card-text v-if='team.hasSchedule === 1 && parseInt(team.numberOfPhasesDefined) !== parseInt(team.numberOfPhases) &&  parseInt(team.numberOfPhases) > 0'>
                  <v-chip color='error' small>All project phases not available</v-chip>
                </v-card-text>

                <draggable :list="team.students" group="teams" @change="log(team.teamName, $event)">
                  <v-card  class="mx-auto mb-2" v-for="student in team.students" :key="student.id" ripple elevation="2">
                    <v-card-text>
                      <div class="d-flex justify-space-between">
                        {{student.rollNumber}} {{student.isFemale | gender}}
                        <v-chip v-if="student.isLeader" color="primary" small>
                          Team Leader
                        </v-chip>
                        <v-btn x-small color='secondary' v-else @click="makeLeader(student.rollNumber, team.teamName)">
                          Make Leader
                        </v-btn>
                      </div>
                      <div class="text--primary">
                        {{student.name}}
                      </div>
                    </v-card-text>
                  </v-card>
                </draggable>

              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col style="height: 90vh; overflow-y: scroll">
          <v-sheet v-if="selectedCourse.id">
            <h3 class="pa-3 mb-2" >Unassigned Students</h3>
          </v-sheet>
          <draggable :list="unassignedStudents" group="teams" @change="log">
            <v-card  class="mx-auto" v-for="student in unassignedStudents" :key="student.id" ripple elevation="2">
              <v-card-text>
                <div>{{student.rollNumber}} {{student.isFemale | gender}} </div>
                <div class="text--primary">
                  {{student.name}}
                </div>
              </v-card-text>
            </v-card>
          </draggable>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialog"
        max-width="800"
      >
        <v-card>
          <v-card-title class="text-h5">
            Assign / Update Marks for {{selectedTeam}}
          </v-card-title>

          <v-card-text class="d-flex justify-space-between">
            <v-row>
              <v-col class="mt-4">
                Kindly enter the team score here and click on update.
              </v-col>
              
              <v-col md="3">
                <v-text-field
                  label="Score"
                  hide-details 
                  single-line 
                  type="number"
                  v-model="teamScore"
                >
                </v-text-field>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-text class="d-flex justify-space-between">
            <v-row>
              <v-col class="mt-4">
                Additionally penalize this team for any malpractice. [Penalty %]
              </v-col>
              
              <v-col md="3">
                <v-text-field
                  label="Penalty %"
                  hide-details 
                  single-line 
                  type="number"
                  v-model="teamPenalty"
                >
                </v-text-field>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-4"
              @click="dialog = false"
            >
              Close
            </v-btn>

            <v-btn
              class="ml-4"
              color="primary"
              @click="updateTeamScore"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mdiCheckCircleOutline, mdiMinusCircleOutline, mdiAlertCircleOutline, mdiPencil, mdiDownloadCircleOutline } from '@mdi/js'
import draggable from "vuedraggable";
import { getRandomColor } from "../../utils/colors";
import {  fetchStudentWithoutProjectTeams, 
          fetchProjectTeamAndStudents,
          addStudentToProjectTeam, 
          fetchProjectReviewParams,
          makeTeamLeader,
          saveProjectTeamScore,
          updateReviewParameters,
          downloadProjectData,
          deleteStudentFromProjectTeam } from '../../api/course';
import AllReviewsDialog from './AllReviewsDialog.vue';
import StudentCommentsDialog from './StudentCommentsDialog.vue'; 
import ApprovalModal from './ApprovalModal.vue';
import InfoDialog from '../../components/InfoDialog.vue';
import StudentScoresDialog from './StudentScoresDialog.vue';
import ReviewDialog from './ReviewDialog.vue';
import { exportProjectData } from '../../utils/xlsUtils'

export default {
  components: {
    draggable,
    ApprovalModal,
    InfoDialog,
    AllReviewsDialog,
    StudentCommentsDialog,
    StudentScoresDialog,
    ReviewDialog
  },
  data() {
    return {
      selectedCourse: {},
      teams:[],
      unassignedStudents: [],
      mdiCheckCircleOutline,
      mdiMinusCircleOutline,
      mdiAlertCircleOutline,
      mdiDownloadCircleOutline,
      mdiPencil,
      infoDialog: false,
      dialog: false,
      selectedTeam: '',
      reviewPhase: 0,
      teamScore: 0,
      teamPenalty: 0,
      isCalcKnee: true,
      report1: false,
      report2: false,
    }
  },
  filters: {
    gender: function (value) {
      if (!value) return '(M)'
      return '(F)'
    }
  },
  computed: {
    courses(){    
        let courses = [];
        
        for(let i = 0; i < this.$store.getters.getCourses.length; i++){
            let course = {}
            let current = this.$store.getters.getCourses[i]

            if(current.isActive){
              course['desc'] = `[${current.courseName}]`
              course['id'] = current.courseName
              courses.push(course)
            }
        }
        
        return courses
    },
  },
  methods:{
    cardColor(idx){
      return { background : getRandomColor(idx) }
    },
    log: function(teamName, evt) {
      if(evt && evt.added){
        this.addToTeam(evt.added, teamName)
      }

      if(evt && evt.removed){
       this.deleteFromTeam(evt.removed)
     }
    },
    scoreDialog(selectedTeam, teamScore, penalty) {
      console.log(selectedTeam, teamScore, penalty)
      this.dialog = !this.dialog
      this.teamPenalty = penalty

      if(teamScore && Number.isInteger(parseInt(teamScore)) ){
        this.teamScore = teamScore
      }
      else{
        this.teamScore = 0
      }
      this.selectedTeam = selectedTeam
    },
    closedApprovalModal(){
      this.fetchTeams()
    },
    async exportData(reviewPhase){
      let params = {
        courseName: this.selectedCourse.id,
        reviewPhase: reviewPhase
      }

      try{
        if(reviewPhase === 1)
          this.report1 = true
        else
          this.report2 = true

        const projectData = await downloadProjectData(params);
        exportProjectData(projectData.data, reviewPhase);
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
      finally{
        if(reviewPhase === 1)
          this.report1 = false
        else
          this.report2 = false
      } 
    },
    async updateTeamScore(){

      let params = {
        teamName : this.selectedTeam,
        teamScore: this.teamScore,
        courseName: this.selectedCourse.id,
        penalty: this.teamPenalty ? this.teamPenalty : 0
      }

      try{
        await saveProjectTeamScore(params)
        this.fetchTeams()

        this.$toast.open({
          message: 'Team Score updated',
          type: 'success',
        });

        this.dialog = false;
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      } 

    },
    async makeLeader(rollNumber, teamName){
      try{
        await makeTeamLeader({rollNumber, teamName })   
        this.fetchTeams()
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
    },
    async fetchTeams(){
      if( this.selectedCourse && this.selectedCourse.id ){
        try{
          const studentTeams = await fetchProjectTeamAndStudents({courseName: this.selectedCourse.id })   
          let cleanData = []

          for(let i = 0; i < studentTeams.data.length; i++){
            let data = studentTeams.data[i]
            let students = JSON.parse(data.students)
            
            if(students[0].id){
              data.students= students
            }
            else{
              data.students= []
            }

            cleanData.push(data)
          }

          this.teams = cleanData

          const reviewParams = await fetchProjectReviewParams({courseName: this.selectedCourse.id })

          if(reviewParams && reviewParams.data){
            this.reviewPhase = reviewParams.data.reviewNumber;
            this.isCalcKnee = reviewParams.data.isCalcKnee
          }
          else{
            this.reviewPhase = 0;
            this.isCalcKnee = true;
          }
        }
        catch(e){
          if(e.code === 'ERR_BAD_REQUEST'){
            this.$toast.open({
              message: e.response.data.details || e.response.data.error.message,
              type: 'error',
            });
          }
          else{
            this.$toast.open({
              message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
              type: 'error',
            });
          }
        }
        this.studentsWithoutTeams()
      }
    },
    async saveReviewParameters(reviewPhase){
      let params = {
        isCalcKnee: this.isCalcKnee,
        courseName: this.selectedCourse.id,
        reviewPhase: reviewPhase
      }

      try{
        await updateReviewParameters(params)
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      } 
    },
    async studentsWithoutTeams(){
      if( this.selectedCourse && this.selectedCourse.id ){
        const studentTeams = await fetchStudentWithoutProjectTeams({courseName: this.selectedCourse.id })
        this.unassignedStudents = studentTeams.data
      }
    },
    removeCourse(){
      this.teams = []
      this.unassignedStudents = []
    },
    async addToTeam(student, teamName){
      let params = {
        rollNumber : student.element.rollNumber,
        courseName: this.selectedCourse.id,
        teamName: teamName
      }

      try{
        await addStudentToProjectTeam(params)
        this.fetchTeams()
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }      
    },
    async deleteFromTeam(student){
      let params = {
        rollNumber : student.element.rollNumber,
        courseName: this.selectedCourse.id
      }

      await deleteStudentFromProjectTeam(params)
    },
  },
}
</script>
