<template>
    <div class="d-flex justify-space-between">
        <v-dialog
            v-model="dialog"
            width="1024"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    dark
                    color="primary"
                    class="ma-4"
                >
                    Open review dialog
                </v-btn>
            </template>  

            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    Project Guide's Feedback
                </v-card-title>

                <v-textarea
                  height="140"
                  class="pl-4 pr-4"
                  v-model="feedback"
                  filled
                  label="Your Feeback"
                  placeholder="Your project feedback please..."
                >
                </v-textarea>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="saveFeedback"
                    >
                        Save Feedback
                    </v-btn>
                    <v-btn
                        color="secondary"
                        outlined
                        @click="dialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { fetchProjectMentorFeedbackAdmin, saveProjectMentorFeedbackAdmin } from '../../api/course'

    export default {
        props: {
            teamName: { type: String, default: null },
            courseName: { type: String, default: null }
        },
        mounted(){ 
        },
        computed: {
        },
        data () {
            return {
                dialog: false,
                feedback: ''
            }
        },
        methods: {
            async fetchFeedback(teamName, courseName){
                try{
                    const apiData = await fetchProjectMentorFeedbackAdmin({teamName, courseName})
                    
                    if(apiData && apiData.data && apiData.data.feedback){
                        this.feedback = apiData.data.feedback
                    }
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.response.data.details || e.response.data.error.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                            type: 'error',
                        });
                    }
                }
            },
            async saveFeedback(){
                try{
                    let teamName = this.teamName
                    let courseName = this.courseName
                    let feedback  = this.feedback
                    
                    //Save the feedback
                    await saveProjectMentorFeedbackAdmin({teamName, courseName, feedback})

                    this.$toast.open({
                        message: 'Feedback saved.',
                        type: 'success',
                    });
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.response.data.details || e.response.data.error.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                            type: 'error',
                        });
                    }
                }
            },
        },
        watch:{
            dialog(n, o) {
                if(n) {
                    this.fetchFeedback(this.teamName, this.courseName)
                }
            },
        }
    }
</script>