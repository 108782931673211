<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-dialog
                v-model="dialog"
                width="1024"
                v-if="showReviewDialog"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-tooltip left v-bind="attrs" v-on="on">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                outlined
                                fab
                                dark
                                :color="color"
                                @click="dialog = true"
                            >
                                <v-icon>
                                    {{ mdiCheckDecagram }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{tooltipText}}</span>
                    </v-tooltip>
                </template> 
                <v-card class="mt-3">
                    <v-card-title class='d-flex justify-space-between'>
                        <span>Project Schedule</span>
                        <span>
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                fab
                                outlined
                                dark
                                color='secondary'
                                @click="dialog = false"
                            >
                                <v-icon>
                                    {{ mdiCloseCircle }}
                                </v-icon>
                            </v-btn>
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-expansion-panels v-model="panel">
                            <v-expansion-panel>
                                <v-expansion-panel-header expand v-model="panel">
                                    <span class="title">
                                        Overall Project Details                                     
                                        <v-chip v-if="projectSchedule.isApproved" class="ml-2" color='success'>Approved</v-chip>
                                        <v-chip v-else class="ml-2" color='error'>Can be Validated & Approved</v-chip>
                                    </span>

                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                <v-row class="pa-4" justify='space-between'>
                                    <div>
                                        <div class="sub-title-1">Project Start & End Dates</div>
                                        <v-date-picker class="mt-3" 
                                            range 
                                            no-title 
                                            show-adjacent-months
                                            readonly
                                            v-model="projectDate">
                                        </v-date-picker>
                                        <div  class="mt-3">
                                            <span v-if="projectDate && projectDate.length > 0">From: {{projectDate[0] | formatDate}}</span>
                                            <span class="ml-2" v-if="projectDate && projectDate.length > 1">To: {{projectDate[1] | formatDate}}</span>
                                        </div>
                                    </div>
                                    <div style="width: 50%">
                                        <div class="sub-title-1">Project Name</div>
                                        <v-text-field disabled v-model="projectSchedule.projectName" class="pb-4"/>
                                        <div class="sub-title-1">Number of Phases</div>
                                        <v-text-field disabled v-model="projectSchedule.numberOfPhases"/>
                                    </div>
                                </v-row>
                                <v-row justify='end' class='pb-4 pr-4'>
                                    <delete-project 
                                        class='ma-4'
                                        @refresh="refresh"
                                        @delete="closeModal"
                                        v-if='projectSchedule.isApproved'
                                        :isDelete="true"
                                        :courseName="courseName"
                                        :teamName="teamName"
                                    />
                                    <delete-project 
                                        class='ma-4'
                                        @refresh="refresh"
                                        @delete="closeModal"
                                        v-if='!projectSchedule.isApproved'
                                        :isDelete="false"
                                        :courseName="courseName"
                                        :teamName="teamName"
                                    />
                                    <approve-project
                                        class='ma-4'
                                        @refresh="refresh"
                                        @approve="closeModal"
                                        v-if='!projectSchedule.isApproved'
                                        :isDelete="false"
                                        :courseName="courseName"
                                        :teamName="teamName"
                                    />
                                </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel v-for="(item,i) in phases" :key="i">
                                <v-expansion-panel-header expand v-model="panel">
                                    <span class="title">
                                    Phase {{phases[i].phaseNumber}}
                                    <span v-if='phases[i].phaseNumber > 0'>
                                        <v-chip v-if="phases[i].isApproved" class="ml-2" color='success'>Approved</v-chip>
                                        <v-chip v-else class="ml-2" color='error'>Can be Validated & Approved</v-chip>
                                    </span>
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row class="pa-4" justify='space-between'>
                                        <div>
                                            <div class="sub-title-1">Phase Start & End Dates</div>
                                            <v-date-picker class="mt-3" 
                                                range 
                                                no-title 
                                                show-adjacent-months
                                                readonly
                                                :value="phaseDate(phases[i])">
                                            </v-date-picker>
                                            <div  class="mt-3">
                                                <span>From: {{phases[i].phaseStart | formatDate}}</span>
                                                <span class="ml-2">To: {{phases[i].phaseEnd | formatDate}}</span>
                                            </div>
                                        </div>
                                        <div style="width: 50%">
                                            <div class="sub-title-1">Phase Name</div>
                                            <v-text-field disabled v-model="phases[i].phaseName" class="pb-4"/>
                                            <v-textarea
                                                readonly
                                                filled
                                                label="Phase Description / Objectives"
                                                class='mt-4 pt-2' 
                                                persistent-hint  
                                                hint="A brief synopsis of the objectives for this phase" 
                                                v-model="phases[i].phaseDescription"></v-textarea>
                                        </div>
                                    </v-row>
                                    <v-row class="pa-4" justify='space-between'>
                                        <v-btn
                                            v-if='phases[i].fileName && phases[i].fileName.length > 0'
                                            color="error"
                                            outlined
                                            class="mt-5 ml-2"
                                            @click='deleteDocument(phases[i].fileName)'
                                        >
                                            Delete Document
                                        </v-btn>
                                        <v-btn
                                            v-if='phases[i].fileName && phases[i].fileName.length > 0'
                                            color="primary"
                                            outlined
                                            class="mt-5"
                                            @click='downloadDocument(phases[i].fileName)'
                                        >
                                            Download Document
                                        </v-btn>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    import { mdiCheckDecagram, mdiCloseCircle } from '@mdi/js'
    import { fetchProjectScheduleAdmin, deleteProjectPhaseDocument, downloadProjectPhaseDocument, fetchProjectPhaseDocument } from '../../api/course'
    import DeleteProject from './DeleteProject.vue'
    import ApproveProject from './ApproveProject.vue'

    export default {
        components: { DeleteProject, ApproveProject },
        props: {
            teamName: { type: String, default: null },
            courseName: { type: String, default: null },
            showReviewDialog: { type: Boolean, default: false },
            shouldApprove: { type: Boolean, default: false }
        },
        computed:{
            color(){
                if(this.shouldApprove){
                    return 'error'
                }
                return 'success'
            },
            tooltipText(){
                if(this.shouldApprove){
                    return 'Can be Validated & Approved'
                }
                return 'Approved!'
            },
            projectDate(){
                if(this.projectSchedule && this.projectSchedule.projectStart && this.projectSchedule.projectEnd){
                    return [this.projectSchedule.projectStart.slice(0,10), this.projectSchedule.projectEnd.slice(0,10)]
                }

                return null
            },
            phases(){
                let phases = this.projectSchedule && this.projectSchedule.phases ? JSON.parse(this.projectSchedule.phases) : []

                if(phases && phases.length > 0 && phases[0].id !== null){
                    return phases
                }

                return []
            }
        },
        mounted(){
        },
        filters: {
            formatDate: function(dt) {
                const date = new Date(dt)
                const day = date.toLocaleString('default', { day: '2-digit' });
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.toLocaleString('default', { year: 'numeric' });
                return day + '-' + month + '-' + year;
            }
        },
        data () {
            return {
                dialog: false,
                mdiCheckDecagram,
                mdiCloseCircle,
                panel: 0,
                projectSchedule: {}
            }
        },
        methods: {
            phaseDate(phase){
                return [phase.phaseStart.slice(0,10), phase.phaseEnd.slice(0,10)]
            },
            refresh() {
                this.fetchScheduleData()
                this.$emit('refresh')
            },
            closeModal() {
                this.dialog = false;
                this.$emit('refresh')
            },
            async deleteDocument(file){
                try{
                    await deleteProjectPhaseDocument({fileName: file})  
                    this.$toast.open({
                        message: 'Project document deleted.',
                        type: 'info',
                    });
                    this.fetchScheduleData()
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                            type: 'error',
                        });
                    }   
                }
            },
            async downloadDocument(file){
                try{
                    //Fetch the file attributes
                    const fileAttrs = await fetchProjectPhaseDocument({fileName: file});
                    const fileName = fileAttrs.data.originalName;

                    //Fetch the actual file
                    const response =  await downloadProjectPhaseDocument({fileName: file});
                    const fileURL = window.URL.createObjectURL(new Blob([response]));
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    fileLink.setAttribute('target', '_blank');

                    document.body.appendChild(fileLink);
                    
                    fileLink.click();
                    fileLink.remove();

                    this.$toast.open({
                        message: 'Project document downloaded.',
                        type: 'info',
                    });
                }
                catch(e){
                    console.log(e)
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                            type: 'error',
                        });
                    }   
                }
            },
            async fetchScheduleData(){
                try{
                    const projectScheduleAPI = await fetchProjectScheduleAdmin({teamName: this.teamName, courseName: this.courseName})
                    if(projectScheduleAPI && projectScheduleAPI.data && projectScheduleAPI.data[0]){
                        this.projectSchedule = projectScheduleAPI.data[0];
                    }
                    else{
                        this.projectSchedule = {}
                    }
                }
                catch(e){
                    this.projectSchedule = {}
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.response.data.details || e.response.data.error.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                        message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                        type: 'error',
                        });
                    }
                }
            },
        },
        watch:{
            dialog(n, o) {
                if(n) {
                    this.fetchScheduleData()
                }
            }
        }
    }
</script>