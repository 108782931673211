let invokeCount = {};

const letters = 'DFDDEEBBDDFECCDEEDFFE'.split('');

export function getRandomColor(colorNumber) {

    if(invokeCount[colorNumber]){
        return invokeCount[colorNumber]
    }

    let color = '#';
    for (let i = 0; i < 6; i++ ) {
        let pseudoRand = (colorNumber * 1.23 * (i + 1)) % letters.length;
        color += letters[Math.floor(pseudoRand)];
    }

    invokeCount[colorNumber] = color;
    return color;
}