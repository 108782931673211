<template>
    <v-dialog
        v-model="approveDialog"
        persistent
        max-width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class='ml-4'
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
                Approve Project & Phases
            </v-btn>
        </template>

        <v-card class='pa-2'>
            <v-card-title>
                Approve the project proposal.
            </v-card-title>
            
                <v-alert
                    dense
                    colored-border
                    color="info"
                    border="right"
                >
                    I confirm that I have validated the schedule(project level and phase data) and am happy to provide approve the same.
                </v-alert>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="accent"
                small
                outlined
                @click="closeDialog"
            >
                Do not approve
            </v-btn>
            <v-btn
                color="primary"
                small
                outlined
                @click="approveParams"
            >
                Approve
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { approveProjectHeader } from '../../api/course'

    export default {
        props: {
            teamName: { type: String, default: null },
            courseName: { type: String, default: null }
        },
        computed:{
        },
        mounted(){
        },
        data () {
            return {
                approveDialog: false,
            }
        },
        methods: {
            async approveParams(){
                try{
                    await approveProjectHeader({teamName: this.teamName, courseName: this.courseName, isApproved: this.isDelete})
                    this.approveDialog = false;
                    this.$emit('approve')
                }
                catch(e){
                    this.closeDialog()
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                            message: e.response.data.details || e.response.data.error.message,
                            type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                        message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                        type: 'error',
                        });
                    }
                }
            },
            closeDialog(){
                this.approveDialog = false;
                this.$emit('refresh')
            }
        },
    }
</script>